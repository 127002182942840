//Catalog Product.jsx

export const product = {
    "ProductID": "Referencia:",
    "NoDescription": "No hay descripción para este producto",
    "AddToCart": "Añadir al carrito",
    "Features": "Características",
    "NoFeatures": "No existe información sobre las características de este producto",
    "Equipment": "Equipamiento",
    "NoEquipment": "No existe información sobre equipamiento para este producto",
    "Specs": "Especificaciones",
    "NoSpecs": "No existe información sobre las especificaciones de este producto", 
    "RelatedProducts": "Productos relacionados"
};