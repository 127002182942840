import React, { useState } from "react";
import styled from "styled-components";
import { Layout, Badge, Menu, Dropdown, Space, Drawer, Button } from "antd";
import { Link } from "react-router-dom";
import mainLogo from "../../src/logo.png";
import { useViewport, DESKTOP } from "../hooks/useViewPort.jsx";
//import SDrawerWishList from "../components/SDrawerWishList.jsx";
import { CaretDownOutlined } from "@ant-design/icons";
// import { ProfileOutlined } from "@ant-design/icons";
import { addToCart } from "../data/AddToCart.jsx";
import { header } from "../data/Header.jsx";

const { Header } = Layout;
const { SubMenu } = Menu;

const AppHeader = ({ data, searchClick }) => {
  const [visible, setVisible] = useState(false);
  const showMenu = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  // const [visible_WishList, setVisible_WishList] = useState(false);

  // const showDrawer_WishList = () => {
  //   setVisible_WishList(true);
  // };

  // const onClose_WishList = () => {
  //   setVisible_WishList(false);
  // };

  let viewport = useViewport();
  const menu = (
    <React.Fragment>
      <Menu.Item key={0}>
        <a href={`${window.location.origin}/catalog`}>{header.Sub1All}</a>
      </Menu.Item>
      {data &&
        data.aggregations.categories.buckets &&
        data.aggregations.categories.buckets.map((category, i) => {
          return (
            <Menu.Item key={i + 1}>
              <a
                href={`${window.location.origin}/catalog?category=${category.key}`}
              >
                {category.key}
              </a>
            </Menu.Item>
          );
        })}
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Header
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 24px",
          backgroundColor: "white",
          lineHeight: "initial",
          maxWidth: 1440,
          width: "100%",
          height: 80,
          alignSelf: "center",
        }}
      >
        {viewport.device !== DESKTOP && (
          <React.Fragment>
            <Action size="middle">
              <div className="mobile-menu" onClick={showMenu}>
                <i className="mi-menu" />
              </div>{" "}
            </Action>
            <Drawer
              placement="left"
              closable={true}
              onClose={onClose}
              visible={visible}
              closeIcon={<i className="mi-close" style={{ fontSize: 24 }} />}
              bodyStyle={{ padding: "64px 0" }}
            >
              <Menu mode="inline">
                <Menu.Item key="sub0">
                  <a href={`${window.location.origin}/`}>{header.Sub0}</a>
                </Menu.Item>
                <SubMenu key="sub1" title={header.Sub1}>
                  {menu}
                </SubMenu>
                <Menu.Item key="sub2">
                  <a href={`${window.location.origin}/blog`}>{header.Sub2}</a>
                </Menu.Item>
                <Menu.Item key="sub3">
                  <a href={`${window.location.origin}/about`}>{header.Sub3}</a>
                </Menu.Item>
                <Menu.Item key="sub4">
                  <a href={`${window.location.origin}/contact`}>
                    {header.Sub4}
                  </a>
                </Menu.Item>
              </Menu>
            </Drawer>
          </React.Fragment>
        )}
        <Logo>
          <Nav to="/">
            <img
              src={mainLogo}
              style={{ width: 140, display: "flex", marginRight: 48 }}
              alt="App logo"
            />
          </Nav>
        </Logo>
        {viewport.device === DESKTOP ? (
          <React.Fragment>
            <Navigation size={40}>
              <Dropdown
                className="ant-dropdown-link"
                overlay={<Menu>{menu}</Menu>}
              >
                <Nav
                  onClick={(e) => e.preventDefault()}
                  to="/catalog"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Catálogo
                  <CaretDownOutlined style={{ fontSize: 14, marginLeft: 8 }} />
                </Nav>
              </Dropdown>
              <Nav to="/blog">{header.Sub2}</Nav>
              <Nav to="/about">{header.Sub3}</Nav>
              <Nav to="/contact">{header.Sub4}</Nav>
            </Navigation>
            <Action size="middle">
              <Navigation size="middle">
                <Nav to="/catalog">
                  <div
                    className="site-search" /*onClick={() => searchClick()}*/
                  >
                    <i className="mi-search" /> <span>Búscame</span>
                  </div>
                </Nav>
              </Navigation>

              {addToCart.EnableSnipcart === "disable" ? (
                <React.Fragment />
              ) : (
                <React.Fragment>
                  <div className="snipcart-customer-signin">
                    <i className="mi-user" />
                  </div>
                  <div className="snipcart-checkout">
                    <Badge
                      count={
                        <span className="snipcart-items-count ant-badge-count"></span>
                      }
                      style={{ backgroundColor: "#1890ff" }}
                    >
                      <i className="mi-shopping-cart" />
                    </Badge>
                  </div>
                </React.Fragment>
              )}
            </Action>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Action size="middle">
              {addToCart.EnableSnipcart === "disable" ? (
                <React.Fragment>
                  <Navigation size="middle">
                    <Nav to="/catalog">
                      <div
                        className="site-search" /*onClick={() => searchClick()}*/
                      >
                        <i className="mi-search" />
                      </div>
                    </Nav>
                  </Navigation>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="snipcart-customer-signin">
                    <i className="mi-user" />
                  </div>
                  <div className="snipcart-checkout">
                    <Badge
                      count={
                        <span className="snipcart-items-count ant-badge-count"></span>
                      }
                      style={{ backgroundColor: "#1890ff" }}
                    >
                      <i className="mi-shopping-cart" />
                    </Badge>
                  </div>
                </React.Fragment>
              )}
            </Action>
          </React.Fragment>
        )}
      </Header>
    </React.Fragment>
  );
};

const Nav = styled(Link)``;

const Logo = styled.div``;

const Navigation = styled(Space)`
  display: flex;
  flex: 1;
  a {
    color: black;
  }

  .ant-dropdown-link:hover {
    span {
      transform: rotate(180deg);
    }
  }
`;

const Action = styled(Space)`
  i {
    font-size: x-large;
    color: black;
    cursor: pointer;
  }
  .site-search {
    display: flex;
    align-items: center;
    cursor: pointer;
    span {
      color: black;
      margin: 0 8px;
    }
  }
`;

export default AppHeader;
