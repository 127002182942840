import { Typography, Row, Col, Drawer, Divider } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import SAutocomplete from "../components/SAutocomplete";
import styled from "styled-components";

const { Title } = Typography;

const Search = ({ visibility, searchClose }) => {
  return (
    <Drawer
      placement={"top"}
      closable={true}
      onClose={() => searchClose()}
      visible={visibility}
      key={"search-site"}
      height={"100vh"}
    >
      <Container>
        <SAutocomplete />
        <Divider />
        <Row justify="space-around" style={{ width: "100%", marginBottom: 64 }}>
          <Col span={10} offset={2}>
            <Title level={5} style={{ textTransform: "uppercase" }}>
              Sugerencias
            </Title>
            <p>Aspiradora para animales</p>
            <p>Aspiradora para animales domésticos</p>
            <p>Accesorios para coche aspiradora</p>
            <p>Fregasuelos y aspirador</p>
            <p>Aspirador con succión total</p>
            <p>Boquilla para aspirador de techo</p>
          </Col>
          <Col span={12}>
            <Title level={5} style={{ textTransform: "uppercase" }}>
              Categorías
            </Title>
            <Categories>
              <a href="/catalog?category=Professional">Professional</a>
              <a href="/catalog?category=Home Garden">Home Garden</a>
              <a href="">Services</a>
            </Categories>
          </Col>
        </Row>
        <Row style={{ width: "100%", marginBottom: 24 }}>
          <Col span={20} offset={2}>
            <Title level={5} style={{ textTransform: "uppercase" }}>
              Secciones
            </Title>
            <Row
              gutter={24}
              style={{ width: "100%", marginTop: 24, marginBottom: 36 }}
            >
              <Col xs={8} sm={4}>
                <SectionImage src="https://dev.alchersan.com/images/products/16026500/main/1.jpg" />
                <SectionTitle>Limpiadoras de alta presión</SectionTitle>
              </Col>
              <Col xs={8} sm={4}>
                <SectionImage src="https://dev.alchersan.com/images/products/17663600/main/1.jpg" />
                <SectionTitle>Barredoras</SectionTitle>
              </Col>
              <Col xs={8} sm={4}>
                <SectionImage src="https://dev.alchersan.com/images/products/11982030/main/2.jpg" />
                <SectionTitle>Robot aspirador</SectionTitle>
              </Col>
              <Col xs={8} sm={4}>
                <SectionImage src="https://dev.alchersan.com/images/products/13280100/main/1.jpg" />
                <SectionTitle>Limpiadora portátil</SectionTitle>
              </Col>
              <Col xs={8} sm={4}>
                <SectionImage src="https://dev.alchersan.com/images/products/14285380/main/1.jpg" />
                <SectionTitle>Aspiradores multifunción</SectionTitle>
              </Col>
              <Col xs={8} sm={4}>
                <SectionImage src="https://dev.alchersan.com/images/products/15124620/main/1.jpg" />
                <SectionTitle>Centro de planchado</SectionTitle>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ width: "100%", marginBottom: 64 }}>
          <Col span={20} offset={2}>
            <Row
              justify="space-between"
              align="middle"
              style={{ marginBottom: 24 }}
            >
              <Title level={2}>Nuestras Recomendaciones</Title>
              <a>
                Ir al catálogo <ArrowRightOutlined />
              </a>
            </Row>
            <Row gutter={[120, 36]}>
              <Col span={8}>
                <SectionImage src="https://dev.alchersan.com/images/products/16297610/main/1.jpg" />
                <SectionTitle>Aspiradores multiuso WD 2</SectionTitle>
              </Col>
              <Col span={8}>
                <SectionImage src="https://dev.alchersan.com/images/products/13491000/main/1.jpg" />
                <SectionTitle>Aspirador manual VC 5</SectionTitle>
              </Col>
              <Col span={8}>
                <SectionImage src="https://dev.alchersan.com/images/products/13942620/main/1.jpg" />
                <SectionTitle>Aspirador a batería HV 1/1 Bp Fs</SectionTitle>
              </Col>
              <Col span={8}>
                <SectionImage src="https://dev.alchersan.com/images/products/16297110/main/1.jpg" />
                <SectionTitle>Aspirador en seco y de ceniza AD 2</SectionTitle>
              </Col>
              <Col span={8}>
                <SectionImage src="https://dev.alchersan.com/images/products/11983000/main/1.jpg" />
                <SectionTitle>
                  Aspiradores multiuso WD 1 Compact Battery
                </SectionTitle>
              </Col>
              <Col span={8}>
                <SectionImage src="https://dev.alchersan.com/images/products/11982820/main/1.jpg" />
                <SectionTitle>
                  Aspiradora manual VC 4 sin cable Plus
                </SectionTitle>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Drawer>
  );
};

const Container = styled(Row)`
  max-width: 1024px;
  margin: auto;

  p {
    margin: 0;
  }
`;

const Categories = styled.div`
  display: flex;
  flex-direction: column;
`;

const SectionImage = styled.img`
  width: 100%;
  object-fit: contain;
  margin-bottom: 6px;
`;

const SectionTitle = styled.p`
  font-size: 14px;
  line-height: 14px;
  text-align: center;
`;

export default Search;
