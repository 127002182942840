import styled from "styled-components";
import ImageIntro from "../../images/karcher_slider_1.jpeg";
import { useViewport, DESKTOP } from "../../hooks/useViewPort.jsx";
import React from "react";

const IntroSection = () => {
  let viewport = useViewport();
  return (
    <React.Fragment>
      {viewport.device === DESKTOP ? (
        <Container>
          <Content style={{ width: "32%" }}>
            <h1 style={{ fontSize: 64, fontWeight: 800, lineHeight: "78px" }}>
              Distribuidor Kärcher en Asturias
            </h1>
            <p style={{ fontSize: 18 }}>
              Comercial Alchersán haciendo tu trabajo más fácil <br /> con los
              mejores productos y un servicio profesional.
            </p>
          </Content>
          <Media style={{ width: "60%" }}>
            <Square />
            <Image
              style={{ width: "120%", marginTop: 48, marginLeft: 48 }}
              src={ImageIntro}
            />
          </Media>
        </Container>
      ) : (
        <Container style={{ flexDirection: "column", marginTop: 48 }}>
          <Content style={{ width: "100%" }}>
            <h1 style={{ fontSize: 48, fontWeight: 800, lineHeight: "56px" }}>
              Distribuidor Kärcher en Asturias
            </h1>
            <p style={{ fontSize: 16 }}>
              Comercial Alchersán haciendo tu trabajo más fácil <br /> con los
              mejores productos y un servicio profesional.
            </p>
          </Content>
          <Media>
            <Square />
            <Image style={{ width: "100%" }} src={ImageIntro} />
          </Media>
        </Container>
      )}
    </React.Fragment>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 24px;
`;

const Content = styled.div``;

const Media = styled.div``;

const Image = styled.img`
  position: relative;
`;

const Square = styled.div`
  display: ${(props) => (props.viewport === "DESKTOP" ? "block" : "none")};
  position: absolute;
  right: 0;
  width: 600px;
  height: 600px;
  background-color: #f2f4f7;
`;

export default IntroSection;
