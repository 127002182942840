//Catalog Catalog.jsx

export const catalog = {
    "TotalResults": "Resultados:",
    "ShowFilters": "Mostrar filtros",
    "CancelFilter": "Cancelar",
    "ApplyFilter": "Aplicar",
    "CategoriesHeader": "Categorías",
    "PriceHeader": "Precio",
    "Filters": "Filtros",
    "NoFilters": "No hay filtros disponibles"
};