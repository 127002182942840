import React, { useState, useCallback, useRef } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import SCard from "../components/SCard";
import { Button, Row, Col, Layout, Collapse, Spin, Drawer } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import styled from "styled-components";
import SCategories from "../components/SCategories";
import SPrice from "../components/SPrice";
import SSort from "../components/SSort";
import SAutocomplete from "../components/SAutocomplete";
import useScrollFilter from "../hooks/useScrollFilter";
import { useViewport, MOBILE, TABLET, DESKTOP } from "../hooks/useViewPort.jsx";
import { catalog } from "../data/Catalog.jsx";

const { Sider, Content } = Layout;
const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

let queryPrice = "",
  queryCategories = "",
  querySort = "&fieldsort=title.keyword&order=asc",
  querySearch = "";

const Catalog = ({ location }) => {
  let viewport = useViewport();
  const [fullQuery, setFullQuery] = useState(
    location.search.split("?") && location.search.split("?").length > 1
      ? location.search.split("?")[1]
      : ""
  );
  const [pageNumber, setPageNumber] = useState(1);
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const { data, products, hasMore, loading, error, autocomplete } =
    useScrollFilter(fullQuery, pageNumber);

  const observer = useRef();
  const lastProductRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasMore) {
            setPageNumber((prevPageNumber) => prevPageNumber + 1);
          }
        },
        { threshold: 1 }
      );
      if (node) {
        observer.current.observe(node);
      }
    },
    [loading, hasMore]
  );

  function applyFilterCategories(evt) {
    queryCategories = "&" + evt.query;
    if (queryCategories !== "") {
      setFullQuery(`${querySearch}${queryCategories}${queryPrice}${querySort}`);
      setPageNumber(1);
    } else {
      setFullQuery(`${queryPrice}${querySort}`);
      setPageNumber(1);
    }
  }

  function applyFilterPrice(evt) {
    queryPrice = "&" + evt.query;
    if (queryPrice !== "") {
      setFullQuery(`${querySearch}${queryCategories}${queryPrice}${querySort}`);
      setPageNumber(1);
    } else {
      setFullQuery(`${queryCategories}${querySort}`);
      setPageNumber(1);
    }
  }

  function applyFilterSort(evt) {
    querySort = "&" + evt.query;
    if (querySort !== "") {
      setFullQuery(`${querySearch}${queryCategories}${queryPrice}${querySort}`);
      setPageNumber(1);
    } else {
      setFullQuery(`${queryCategories}`);
      setPageNumber(1);
    }
  }

  function applyFilterSearch(evt) {
    querySearch = evt.query;
    console.log("QUERY SEARCH:", querySearch);
    if (querySearch !== "") {
      setFullQuery(`${querySearch}${queryCategories}${queryPrice}${querySort}`);
      setPageNumber(1);
    } else {
      setFullQuery(`${querySearch}`);
      setPageNumber(1);
    }
  }

  return (
    <React.Fragment>
      {data && (
        <Layout style={{ minHeight: "80vh" }}>
          {viewport.device === DESKTOP && (
            <Sider
              width="20%"
              theme="light"
              style={{ padding: "0 12px", marginTop: "40px" }}
            >
              <Collapse
                defaultActiveKey={["1", "2"]}
                ghost
                extra={<span>325</span>}
              >
                <Panel header={catalog.CategoriesHeader} key="1">
                  <SCategories
                    onCategoriesQuery={applyFilterCategories}
                    data={data}
                  ></SCategories>
                </Panel>
                <Panel header={catalog.PriceHeader} key="2">
                  <SPrice
                    maxValue={data.aggregations.maxPrice.value}
                    minValue={data.aggregations.minPrice.value}
                    range={true}
                    onPriceQuery={applyFilterPrice}
                  />
                </Panel>
              </Collapse>
            </Sider>
          )}

          <MainContent>
            <Row style={{ paddingRight: 24 }}>
              <SAutocomplete
                onSearchQuery={applyFilterSearch}
                autocomplete={autocomplete}
              />
            </Row>
            {viewport.device !== DESKTOP && (
              <Row>
                <Button type="primary" onClick={showDrawer}>
                  {catalog.ShowFilters}
                </Button>
                <Drawer
                  title={catalog.Filters}
                  placement="left"
                  width="100vw"
                  closable={true}
                  onClose={onClose}
                  visible={visible}
                  footer={
                    <div
                      style={{
                        textAlign: "right",
                      }}
                    >
                      <Button onClick={onClose} style={{ marginRight: 8 }}>
                        {catalog.CancelFilter}
                      </Button>
                      <Button onClick={onClose} type="primary">
                        {catalog.ApplyFilter}
                      </Button>
                    </div>
                  }
                >
                  {data ? (
                    <Collapse
                      defaultActiveKey={["1", "2"]}
                      ghost
                      extra={<span>325</span>}
                    >
                      <Panel header={catalog.CategoriesHeader} key="1">
                        <SCategories
                          onCategoriesQuery={applyFilterCategories}
                          data={data}
                        ></SCategories>
                      </Panel>
                      <Panel header={catalog.PriceHeader} key="2">
                        <SPrice
                          maxValue={data.aggregations.maxPrice.value}
                          minValue={data.aggregations.minPrice.value}
                          range={true}
                          onPriceQuery={applyFilterPrice}
                        />
                      </Panel>
                    </Collapse>
                  ) : (
                    <p> {catalog.NoFilters} </p>
                  )}
                </Drawer>
              </Row>
            )}
            <Row
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingRight: 24,
              }}
            >
              <TotalResults>
                {catalog.TotalResults} <b>{data.hits}</b>
              </TotalResults>
              <SSort onSortQuery={applyFilterSort} />
            </Row>
            <Row>
              <TransitionGroupCatalog>
                <Row
                  gutter={[24, 24]}
                  style={{ width: "100%" }}
                  justify="center"
                  align="center"
                >
                  {products.map((item, index) => (
                    <CSSTransitionCatalog
                      in={!loading}
                      classNames="fade"
                      timeout={420}
                      key={index}
                    >
                      {products.length === index + 1 ? (
                        <Col key={item.id} xs={24} sm={12} xl={8} xxl={6}>
                          <div style={{ height: "100%" }} ref={lastProductRef}>
                            <SCard
                              title={item.title}
                              brand=""
                              hoverable={true}
                              price={item.price}
                              url={item.url}
                              discount={item.final_price}
                              cover={item.images}
                              available={item.available}
                              details={`Ref. ${item.id}`}
                              productId={item.id}
                            ></SCard>
                          </div>
                        </Col>
                      ) : (
                        <Col key={item.id} xs={24} sm={12} xl={8} xxl={6}>
                          <SCard
                            title={item.title}
                            brand=""
                            hoverable={true}
                            price={item.price}
                            url={item.url}
                            discount={item.final_price}
                            cover={item.images}
                            available={item.available}
                            details={`Ref. ${item.id}`}
                            productId={item.id}
                          ></SCard>
                        </Col>
                      )}
                    </CSSTransitionCatalog>
                  ))}
                </Row>
              </TransitionGroupCatalog>
            </Row>
            <Loading>
              {loading && <Spin tip="Loading..." indicator={antIcon} />}
            </Loading>
            <Error>{error && "Error"}</Error>
          </MainContent>
        </Layout>
      )}
    </React.Fragment>
  );
};

const MainContent = styled(Content)`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding: 36px;
`;

const TotalResults = styled.div``;

const Loading = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 0;
`;

const Error = styled.div``;

const TransitionGroupCatalog = styled(TransitionGroup)`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
`;

const CSSTransitionCatalog = styled(CSSTransition)`
  .fade-enter {
    opacity: 0;
  }
  .fade-enter-active {
    opacity: 1;
    transition: opacity 480s ease-in;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit-active {
    opacity: 0.2;
    transition: opacity 120ms ease-out;
  }
  .fade-exit-done {
    opacity: 0;
  }
  .fade-appear {
    opacity: 0;
  }
  .fade-appear-active {
    opacity: 1;
    transition: opacity 480s ease;
  }
`;

export default Catalog;
