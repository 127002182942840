//Intro

export const DataAboutIntro = {
  "introWidth": "100%",
  "introSrc": "https://alchersan.com/img/new/about_us.jpeg",
  "introTitle": "",
  "introHeading": "",
  "introParagraph": "",
};

// Nuestro servicios


export const AboutServices = {
  "title": "Nuestros servicios",
  "service": [
    {
      title: "Venta",
      description:
        "Tenemos lo que necesitas, disponemos de todos los productos Kärcher que pueden hacer tu vida más fácil.",
      image: "https://alchersan.com/img/karcher/desktop/s1.jpg",
    },
    {
      title: "Reparación",
      description:
        "La vida no es perfecta, y la de las máquinas tampoco. Por eso nuestros mecánicos, harán lo posible para que tengas tu máquina en las mejores condiciones independientemente del uso que le des.",
      image: "https://alchersan.com/img/new/repair.jpeg",
    },
    {
      title: "Reparto",
      description:
        "Esté donde esté tu negocio allí tendrás un profesional al servicio de tu máquina Kärcher.",
      image: "https://alchersan.com/img/karcher/desktop/s3.jpg",
    },
  ]
};


// El equipo


export const AboutTeam = {
  "title": "Nuestro Equipo",
  "service": [
    {
      title: "Ramiro Alvarez García",
      description:
        "Taller y Ventas",
      image: "",
    },
    {
      title: "Marta Fernández Rosal",
      description:
        "Contabilidad",
      image: "",
    },
    {
      title: "Adrián Melendi Cuartas",
      description:
        "Taller y Ventas",
      image: "",
    },
    {
      title: "Borja Zamarreño",
      description:
        "Taller",
      image: "",
    }
  ]
};

//Form

export const DataAboutForm = {
  "title": "Pregúntanos!",
  "name": {
    "label": "Nombre",
    "placeholder": "Nombre"
  },
  "email": {
    "label": "Email",
    "placeholder": "Email"
  },
  "message": {
    "label": "Mensaje",
    "placeholder": "En qué podemos ayudarte?"
  },
  "submit": "Enviar"
};
